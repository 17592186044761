import { constants } from "../constants";

export const saveShopItem = (shopItem) => (dispatch) => {
  dispatch({
    type: constants.SAVE_SHOP_ITEM,
    payload: shopItem,
  });
};

export const updateShopObject = (shopObject) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_SHOP_OBJECT,
    payload: shopObject,
  });
};

export const updateIsPostpaid = (bool) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_IS_POSTPAID,
    payload: bool,
  });
};

export const resetShopState = () => {
  return {
    type: constants.RESET_SHOP_STATE,
  };
};